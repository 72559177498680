import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import Authenticated from "@/layouts/Authenticated";
import { TODO } from "@/types";

import "../css/app.css";
import "./bootstrap";
import { ThemeProvider } from "@/providers/ThemeProvider";
import GuestLayout from "@/layouts/GuestLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Global from "@/layouts/Global";

import * as Sentry from "@sentry/react";
import { TawkMessengerProvider } from "@/providers/TawkMessengerProvider";
import { TooltipProvider } from "@/components/ui";

const appName = import.meta.env.VITE_APP_NAME || "QVirtual";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.MODE === "development" ? false : 3,
    },
  },
});

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const page: TODO = await resolvePageComponent(
      `./pages/${name}.tsx`,
      import.meta.glob("./pages/**/**/*.tsx"),
    );

    page.default.layout = (page: React.ReactNode) => (
      <Global>
        <Authenticated>{page}</Authenticated>
      </Global>
    );

    if (name.startsWith("Guest/"))
      page.default.layout = (page: React.ReactNode) => (
        <Global>
          <GuestLayout>{page}</GuestLayout>
        </Global>
      );

    return page;
  },
  setup({ el, App, props }) {
    const root = createRoot(el);

    Sentry.init({
      release: props.initialPage.props.version as string,
      dsn: "https://bac67c68a67e4175a15298d98402f573@o1178400.ingest.us.sentry.io/6290211",
      enabled: import.meta.env.MODE !== "development",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    root.render(
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <TawkMessengerProvider>
              <TooltipProvider>
                <App {...props} />
              </TooltipProvider>
            </TawkMessengerProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </StrictMode>,
    );
  },
  progress: {
    color: "#c00000",
    delay: 800,
  },
});
